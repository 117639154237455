
import { useAppDispatch, useAppSelector } from '@app/app.hook'

import { MOBILE_RESPONSIVE_LIMIT } from '@app/app.constants'
import { selectStrings } from '@app/slices/slice.app'
import Open from '@reasonWithMe/components/types/multi-question/Open'
import OpenList from '@reasonWithMe/components/types/multi-question/OpenList'
import Scale from '@reasonWithMe/components/types/multi-question/Scale'
import {
    BodyMapPreviousQuestion,
    OpenListPreviousQuestions
} from '@reasonWithMe/components/types/open-list/OpenList'
import { setAnswerValue } from '@reasonWithMe/slice'
import {
    OpenListValues,
    OpenMultiQuestionActions,
    OpenMultiQuestionOption,
    QuestionAnswer,
    QuestionInterfaceActions,
    ReasonWithMeState
} from '@reasonWithMe/type'
import produce from 'immer'
import _ from 'lodash'
import { useEffect, useMemo, useReducer, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { v4 as uuidV4 } from 'uuid'

interface ComponentProps {
    divRef: React.RefObject<HTMLDivElement>,
    enableValidation: boolean
    questionInterface: ReasonWithMeState['questionInterface'],
    componentDispatch?: React.Dispatch<QuestionInterfaceActions>
    enableTruncation: boolean
    setEnableTruncation: React.Dispatch<React.SetStateAction<boolean>>
}

const RenderedInterface = (
    {
        formsState,
        formsDispatch,
        enableValidation,
        enableTruncation,
        setEnableTruncation
    }:{
        formsState: {
            options: OpenMultiQuestionOption[];
        }
        formsDispatch: React.Dispatch<OpenMultiQuestionActions>
        enableValidation: boolean
        enableTruncation: boolean
        setEnableTruncation: React.Dispatch<React.SetStateAction<boolean>>
    }
) => {
    // with activeIndex, get the previousanswers that meets this.
    const activeOption = _.find(formsState.options, o => {
        return o.isActive === true
    })

    let renderedGroups:(JSX.Element|undefined)[] = []

    // console.log(questionAnswers, activeOption)

    if (activeOption) {
        renderedGroups = _.map(activeOption.answers, (obj, index) => {
            let arr: JSX.Element[] = []

            if (obj.question.answerType === 'scale') {
                arr = _.map(obj.answer, (inner, innerIndex) => {
                    const key2 = [
                        activeOption.id,
                        obj.question.id,
                        inner.id
                    ].join('-')

                    // console.log('key2: ', key2)

                    return <Scale key={key2}
                        ids={{
                            activeOptionId: activeOption.id,
                            questionId: obj.question.id,
                            answerIndex: innerIndex
                        }}
                        obj={obj}
                        answer={inner}
                        formsDispatch={formsDispatch}
                    />
                })
            } else if (obj.question.answerType === 'open') {
                arr = _.map(obj.answer, (inner, innerIndex) => {
                    const key2 = [
                        activeOption.id,
                        obj.question.id,
                        inner.id
                    ].join('-')

                    // console.log('key2: ', key2)

                    return <Open key={key2}
                        enableValidation={enableValidation}
                        ids={{
                            activeOptionId: activeOption.id,
                            questionId: obj.question.id,
                            answerIndex: innerIndex
                        }}
                        obj={obj}
                        answer={inner}
                        formsDispatch={formsDispatch}
                    />
                })
            } else if (obj.question.answerType === 'open-list') {
                arr = _.map(obj.answer, (inner, innerIndex) => {
                    const key2 = [
                        activeOption.id,
                        obj.question.id,
                        inner.id
                    ].join('-')

                    // console.log('key2: ', key2)

                    return <OpenList key={key2}
                        enableValidation={enableValidation}
                        ids={{
                            activeOptionId: activeOption.id,
                            questionId: obj.question.id,
                            answerIndex: innerIndex
                        }}
                        obj={obj}
                        answer={inner}
                        multiFormsDispatch={formsDispatch}
                        enableTruncation={enableTruncation}
                        setEnableTruncation={setEnableTruncation}
                    />
                })
            }

            return <div key={obj.question.id} className={'col'}>
                <div className={'g-4 row row-cols-1'}>
                    {arr}
                </div>
            </div >
        })
    }

    return <>{renderedGroups}</>
}

const MultiQuestion = ({
    divRef,
    // if this value is true, update the dom elements.
    enableValidation,
    questionInterface,
    componentDispatch,
    enableTruncation,
    setEnableTruncation
}: ComponentProps) => {
    const dispatch = useAppDispatch()
    const strings = useAppSelector(selectStrings)

    const [previousAnswers, setPreviousAnswers] = useState<any[]>([])
    const [questionAnswers, setQuestionAnswers] = useState<(QuestionAnswer & {id: string})[]>([])
    const isMobile = useMediaQuery({
        query: `(max-width: ${ MOBILE_RESPONSIVE_LIMIT })`
    })

    // useEffect(() => {
    //     console.log('db_name is: ', questionInterface.currentReasonWithMeResponse.reasoningData.question?.db_name)
    // // }, [questionInterface.currentReasonWithMeResponse.reasoningData.question?.db_name])
    // }, [questionInterface.currentReasonWithMeResponse.reasoningData.question?.questionId])

    const [formsState, formsDispatch] = useReducer((
        state: {
            options: OpenMultiQuestionOption[]
        },
        action: OpenMultiQuestionActions
    ) => {
        switch (action.type) {
            case 'SET_ACTIVE_BUTTON':
                return produce(state, draft => {
                    draft.options.forEach(option => {
                        if (_.isEqual(option.id, action.id)) {
                            // don't change
                            option.isActive = action.boolean
                        } else {
                            option.isActive = false
                        }
                    })
                })
            case 'ADD_CLICKED_BUTTON':
                return produce(state, draft => {
                    const found = _.find(draft.options, (o) => {
                        return _.isEqual(o.id, action.id)
                    })

                    if (found) {
                        found.isClickedOnce = action.boolean
                    }
                })
            case 'ADD_OPTION':
                return produce(state, draft => {
                    draft.options = action.arr
                })
            case 'UPDATE_ANSWER_VALUE':
                return produce(state, draft => {
                    const outer = _.find(draft.options, (o) => {
                        return _.isEqual(o.id, action.ids.activeOptionId)
                    })

                    if (outer) {
                        const inner = _.find(outer.answers, (o) => {
                            return _.isEqual(o.question.id, action.ids.questionId)
                        })

                        if (inner) {
                            inner.answer[action.ids.answerIndex]
                                .hasAnswer = action.answer.hasAnswer
                            inner.answer[action.ids.answerIndex]
                                .actualValue = action.answer.actualValue
                        }
                    }
                })
            default:
                return state
        }
    }, {
        options: []
    })

    useEffect(() => {
        console.log(formsState)
        // console.log('form state from open multi questions', formsState)
        const data = _.cloneDeep(questionInterface.currentReasonWithMeResponse.reasoningData)

        const updatedArr = formsState.options.map(item => {
            const {
                // we want to include isClickedOnce now.
                isActive, ...rest
            } = item

            const arr = _.map(rest.answers, item2 => {
                const { ...rest } = item2
                return {
                    ...rest,
                    answer: _.map(item2.answer, item3 => {
                        const { hasAnswer, ...rest2 } = item3
                        return rest2
                    })

                }
            })

            return {
                ...rest,
                answers: arr
            }
        })

        // console.log('update arr is: ', updatedArr)
        const result = {
            ...(data.question
                ?.previousQuestionData?.questionUseAnswerFrom && {
                questionUseAnswerFrom: data.question
                    ?.previousQuestionData?.questionUseAnswerFrom
            }),
            ...(data
                ?.previousQuestionData?.userQuestionIdentifier && {
                userQuestionIdentifier: data
                    ?.previousQuestionData?.userQuestionIdentifier
            }),
            answers: updatedArr
        }

        if (componentDispatch !== undefined) {
            componentDispatch({
                type: 'SET_ANSWER_VALUE',
                value: result
            })
        } else {
            dispatch(setAnswerValue(result))
        }
    }, [formsState])

    // when a flag for multi-question to validate the component is true, enable said behavior.

    useEffect(() => {
        // check original reference for input-list-split
        const data = _.cloneDeep(questionInterface.currentReasonWithMeResponse.reasoningData)

        // do if statements for clarity.

        if (data.question?.previousQuestionData?.questionType === 'open-list') {
            setPreviousAnswers(data.previousQuestionData?.questionAnswers?.answers || [])
        } else if (data.question?.previousQuestionData?.questionType === 'multi-question') {
            setPreviousAnswers(data.previousQuestionData?.questionAnswers?.answers || [])
        } else if (
            !data.question?.previousQuestionData ||
         _.keys(data.question?.previousQuestionData).length === 0
        ) {
            setPreviousAnswers(data.previousQuestionData?.questionAnswers?.answers || [])
        } else if (data.question?.previousQuestionData?.questionType === 'bodymap') {
            // if bodymap is empty, fill at least one empty string.
            const questionAnswers = data.previousQuestionData?.questionAnswers as QuestionAnswer[]
            if (questionAnswers.length) {
                setPreviousAnswers(questionAnswers)
            } else {
                setPreviousAnswers(
                    [{
                        acqValue: 'general',
                        acqName: 'General'
                    }] as QuestionAnswer[])
            }
        } else if (data.question?.previousQuestionData?.questionType === 'list' ||
             data.question?.previousQuestionData?.questionType === 'multi-list') {
            // this is an array of strings but turn it into acqValue and acqName
            const questionAnswers = data.previousQuestionData?.questionAnswers as string[]
            if (questionAnswers.length) {
                setPreviousAnswers(_.map(questionAnswers, (str) => {
                    return {
                        acqValue: str,
                        acqName: str
                    }
                }))
            }
        }
    }, [questionInterface.currentReasonWithMeResponse.reasoningData.question?.questionId])

    useEffect(() => {
        const data = _.cloneDeep(questionInterface.currentReasonWithMeResponse.reasoningData)

        // check if previous question is open-list.
        if (data.question?.previousQuestionData?.questionType === 'open-list') {
            const conditionFound = data.question?.questionAnswers.filter((o) => {
                return o.answerType !== 'multi-list'
            })

            setQuestionAnswers(_.map(conditionFound, (o) => {
                return {
                    ...o,
                    id: uuidV4()
                }
            }) || [])
        } else if (data.question?.previousQuestionData?.questionType === 'multi-question') {
            const conditionFound = data.question?.questionAnswers.filter((o) => {
                return o.answerType !== 'multi-list'
            })

            setQuestionAnswers(_.map(conditionFound, (o) => {
                return {
                    ...o,
                    id: uuidV4()
                }
            }) || [])
        } else if (!data.question?.previousQuestionData ||
            _.keys(data.question?.previousQuestionData).length === 0) {
            // console.log('data before setting question headers: ', data)

            setQuestionAnswers(_.map(data.question?.questionAnswers, (o) => {
                return {
                    ...o,
                    id: uuidV4()
                }
            }) || [])
        } else if (data.question?.previousQuestionData?.questionType === 'bodymap') {
            const conditionFound = data.question?.questionAnswers.filter((o) => {
                return o.answerType !== 'multi-list'
            })

            setQuestionAnswers(_.map(conditionFound, (o) => {
                return {
                    ...o,
                    id: uuidV4()
                }
            }) || [])
        } else if (data.question?.previousQuestionData?.questionType === 'list' ||
            data.question?.previousQuestionData?.questionType === 'multi-list') {
            const conditionFound = data.question?.questionAnswers.filter((o) => {
                return o.answerType !== 'multi-list'
            })

            setQuestionAnswers(_.map(conditionFound, (o) => {
                return {
                    ...o,
                    id: uuidV4()
                }
            }) || [])
        }
    }, [questionInterface.currentReasonWithMeResponse.reasoningData.question?.questionId])

    useEffect(() => {
        const data = _.cloneDeep(questionInterface.currentReasonWithMeResponse.reasoningData)

        // set the state this way.
        if (data.question?.previousQuestionData?.questionType === 'open-list') {
            const conditionFound = data.question?.questionAnswers.find((o) => {
                return o.answerType === 'multi-list'
            })

            let mappedValues: OpenMultiQuestionOption[] = []

            if (conditionFound) {
                mappedValues = _.compact(
                    _.flatMap(previousAnswers as OpenListPreviousQuestions[], (obj) => {
                        const inputList = obj.inputs.find(input => input
                            .options.answerType === 'input-list')

                        if (inputList && _.isArray(inputList.answerValue)) {
                            const restInputs = obj.inputs.filter(input => {
                                return input.options.answerType === 'input'
                            })

                            // changed to just get the rest of the inputs that are input.
                            // const renderButtons = restInputs
                            //     .every(input => input.options.answerType === 'input')

                            const buttonContent = inputList.answerValue.flatMap((
                                answer, answerIndex
                            ) => {
                                const buttonText = [
                                    ...restInputs.map(input => input.answerValue), answer
                                ].join(', ')

                                return {
                                    id: uuidV4(),
                                    isClickedOnce: false,
                                    isActive: false,
                                    label: buttonText,
                                    answers: _.map(questionAnswers, (outer) => {
                                        return {
                                            question: outer,
                                            answer: _.map(outer?.answerQuestions,
                                                () => {
                                                    return {
                                                        id: uuidV4(),
                                                        hasAnswer: false,
                                                        actualValue: undefined
                                                    }
                                                })
                                        }
                                    })
                                }
                            })

                            return buttonContent
                        } else {
                            return _.map(obj.inputs, (p) => {
                                if (p.options.answerType === 'input') {
                                // this should be a string.
                                    return {
                                        id: uuidV4(),
                                        // START OF BUTTON STATUS
                                        isClickedOnce: false,
                                        isActive: false,
                                        label: p.answerValue as string,
                                        // END OF BUTTON STATUS

                                        answers: _.map(questionAnswers, (outer) => {
                                            return {
                                                question: outer,
                                                answer: _.map(outer?.answerQuestions,
                                                    () => {
                                                        return {
                                                            id: uuidV4(),
                                                            hasAnswer: false,
                                                            actualValue: undefined
                                                        }
                                                    }
                                                )
                                            }
                                        })
                                    }
                                }
                                return null
                            })
                        }
                    })
                )
            }

            formsDispatch({
                type: 'ADD_OPTION',
                arr: mappedValues
            })
        } else if (data.question?.previousQuestionData?.questionType === 'multi-question') {
            let mappedValues: OpenMultiQuestionOption[] = []

            // first of, we need to filter out the values because a question identifier was
            // provided.

            const filteredValues = (previousAnswers as OpenMultiQuestionOption[])
                .flatMap(answerGroup => {
                    return answerGroup.answers.map(answer => {
                        const question = answer.question
                        const questionIdentifier = question?.answerQuestions?.[0]?.questionIdentifier

                        if (questionIdentifier === data.previousQuestionData
                            ?.userQuestionIdentifier) {
                            return {
                                question,
                                answer: answer.answer
                            }
                        }

                        return null
                    })
                }).filter(item => item !== null)

            // console.log('filtered values: ', filteredValues)

            // most of these values have 1 element in the array.

            mappedValues = _.flatMap(filteredValues, o => {
                // to deal with actualValue, because the data can be from any question type.
                // not just input, open-list, scale, etc.
                let label = ''

                if (o?.question.answerType === 'open-list') {
                    const actualValue = o?.answer[0]?.actualValue as OpenListValues[]

                    return _.flatMap(actualValue, inner1 => {
                        return _.flatMap(inner1.inputs, (inner2, inner2Index) => {
                            return {
                                // choosing answer id because it has relevance.
                                id: [inner1.id || '', inner2Index].join('-'),
                                isClickedOnce: false,
                                isActive: false,
                                label: inner2.userTypedAnswer as string,
                                answers: _.map(questionAnswers, (outer) => {
                                    return {
                                        question: outer,
                                        answer: _.map(outer?.answerQuestions,
                                            () => {
                                                return {
                                                    id: uuidV4(),
                                                    hasAnswer: false,
                                                    actualValue: undefined
                                                }
                                            }
                                        )
                                    }
                                })

                            }
                        })
                    })
                } else if (o?.question.answerType === 'scale') {
                    label = o?.answer[0]?.actualValue
                } else if (o?.question.answerType === 'open') {
                    label = o?.answer[0]?.actualValue
                }

                return {
                    // choosing answer id because it has relevance.
                    id: o?.answer[0]?.id || '',
                    isClickedOnce: false,
                    isActive: false,
                    label,
                    answers: _.map(questionAnswers, (outer) => {
                        return {
                            question: outer,
                            answer: _.map(outer?.answerQuestions,
                                () => {
                                    return {
                                        id: uuidV4(),
                                        hasAnswer: false,
                                        actualValue: undefined
                                    }
                                }
                            )
                        }
                    })

                }
            })

            formsDispatch({
                type: 'ADD_OPTION',
                arr: mappedValues
            })
        } else if (
            !data.question?.previousQuestionData ||
             _.keys(data.question?.previousQuestionData).length === 0
        ) {
            // console.log('Question answers after setting: ', questionAnswers)
            // so there are no options... what do you do. ideally no buttons should be rendered
            // but we'll make one option that is selected right away.
            formsDispatch({
                type: 'ADD_OPTION',
                arr: [
                    {
                        id: uuidV4(),
                        isClickedOnce: true,
                        isActive: true,
                        label: 'Invisible Button',
                        answers: _.map(questionAnswers, (outer) => {
                            return {
                                question: outer,
                                answer: _.map(outer?.answerQuestions,
                                    () => {
                                        return {
                                            id: uuidV4(),
                                            hasAnswer: false,
                                            actualValue: undefined
                                        }
                                    })
                            }
                        })
                    }
                ]
            })
        } else if (data.question?.previousQuestionData?.questionType === 'bodymap') {
            const conditionFound = data.question?.questionAnswers.find((o) => {
                return o.answerType === 'multi-list'
            })

            let mappedValues: OpenMultiQuestionOption[] = []

            if (conditionFound) {
                mappedValues = _.map(previousAnswers as BodyMapPreviousQuestion[], (obj) => {
                    return {
                        id: uuidV4(),
                        isClickedOnce: false,
                        isActive: false,
                        referenceObj: obj,
                        label: [
                            obj.acqName || '',
                            obj.acqPositionString ? ['(', obj.acqPositionString, ')'].join('') : ''
                        ].join(' '),
                        answers: _.map(questionAnswers, (outer) => {
                            return {
                                question: outer,
                                answer: _.map(outer?.answerQuestions,
                                    () => {
                                        return {
                                            id: uuidV4(),
                                            hasAnswer: false,
                                            actualValue: undefined
                                        }
                                    })
                            }
                        })
                    }
                })
            }

            formsDispatch({
                type: 'ADD_OPTION',
                arr: mappedValues
            })
        } else if (data.question?.previousQuestionData?.questionType === 'list' ||
            data.question?.previousQuestionData?.questionType === 'multi-list') {
            const conditionFound = data.question?.questionAnswers.find((o) => {
                return o.answerType === 'multi-list'
            })

            let mappedValues: OpenMultiQuestionOption[] = []

            if (conditionFound) {
                mappedValues = _.map(previousAnswers, (obj) => {
                    return {
                        id: uuidV4(),
                        isClickedOnce: false,
                        isActive: false,
                        referenceObj: obj,
                        label: [
                            obj.acqName || ''
                        ].join(' '),
                        answers: _.map(questionAnswers, (outer) => {
                            return {
                                question: outer,
                                answer: _.map(outer?.answerQuestions,
                                    () => {
                                        return {
                                            id: uuidV4(),
                                            hasAnswer: false,
                                            actualValue: undefined
                                        }
                                    })
                            }
                        })
                    }
                })
            }

            formsDispatch({
                type: 'ADD_OPTION',
                arr: mappedValues
            })
        }
    }, [previousAnswers, questionAnswers])

    const selectableButtons = useMemo(() => {
        // get how many buttons in the formState that have a isClickedOnce set to true.
        const countIsClickedOnceButtons = _.filter(formsState.options, (o) => {
            return o.isClickedOnce === true
        }).length

        // then this disable logic will only apply IF the questionAnswersAllowed value is
        // a non-zero value.
        let disableButton = false
        const questionAnswersAllowed = questionInterface.currentReasonWithMeResponse
            .reasoningData.question?.questionAnswersAllowed || 0

        if (questionAnswersAllowed > 0) {
            // check if we've reached ===.
            disableButton = countIsClickedOnceButtons === questionAnswersAllowed
        }

        return formsState.options.map((obj) => {
            const key = [obj.id].join('')

            const numerator = obj.answers.map((outer) => {
                return _.filter(
                    outer.answer,
                    (inner) => inner?.hasAnswer
                ).length
            }).reduce((accumulator, currentValue) => accumulator + currentValue, 0)

            const denominator = questionAnswers.map((o) => {
                return o?.answerQuestions?.length || 0
            }).reduce((accumulator, currentValue) => accumulator + currentValue, 0)

            // console.log(obj)

            const label = <label className={[
                'btn btn-dummy'
            ]
                .join(' ')} htmlFor={key}>

                <div className={'question-checkbox'}>
                    <div className={['card shadow',
                        enableValidation
                            ? numerator < denominator
                                ? 'border border-1 border-danger'
                                : ''
                            : ''
                    ].join(' ')}>
                        <div className={[
                            'align-items-center justify-content-between',
                            ' d-flex flex-row h-100 card-body px-3'
                        ].join(' ')}
                        >
                            <div className={'p col'}>
                                {obj.label}
                            </div>
                            {/* show fraction when clicked once. */}
                            {obj.isClickedOnce && <div className={'p col-auto offset-3'}>
                                {

                                    numerator === denominator
                                        ? <i className={'fa-light fa-check mt-1 mx-auto'}></i>
                                        : [
                                            numerator, denominator
                                        ].join(' / ')
                                }
                            </div>}
                        </div>

                    </div>
                </div>

            </label>

            const onChange = () => {
                if (!disableButton) {
                // show number of questions in a fraction.
                // regardless of what e status it is.
                    formsDispatch({
                        type: 'SET_ACTIVE_BUTTON',
                        id: obj.id,
                        boolean: true
                    })

                    // check if it wasn't clicked once.
                    if (obj.isClickedOnce) {
                    // was clicked
                    } else {
                        formsDispatch({
                            type: 'ADD_CLICKED_BUTTON',
                            id: obj.id,
                            boolean: true
                        })
                    }
                }
            }

            return (
                obj.label !== 'Invisible Button' && <div className={'col'} key={key}>
                    <input type={'checkbox'}
                        className={'btn-check'}
                        autoComplete={'off'}
                        id={key}
                        checked={obj.isActive}
                        // meaning you want to exclude disabling buttons where
                        // you clicked.
                        disabled={disableButton && obj.isClickedOnce === false}
                        onChange={onChange}
                    />
                    {label}
                </div>
            )
        })
    }, [formsState, enableValidation])

    return (
        <div className={['question-multi-question'].join(' ')} ref={divRef}>
            {/* //render an array of buttons */}
            <div className={['container-fluid', isMobile ? 'px-0' : ''].join(' ')}>

                {
                    questionInterface.currentReasonWithMeResponse
                        .reasoningData.question?.questionAnswersAllowed === 0
                        ? <p>{strings.app?.message.error.missing_answers}</p>
                        : ''
                }

                <div className={[
                    'row row-cols-1 row-cols-xl-2 g-3',
                    'btn-group-checkbox-list mt-3 mb-5'
                ].join(' ')}>
                    {selectableButtons}
                </div>
                <div className={'row row-cols-1 g-3 mt-4 mb-3'}>
                    {<RenderedInterface
                        formsState={formsState}
                        formsDispatch={formsDispatch}
                        enableValidation={enableValidation}
                        enableTruncation={enableTruncation}
                        setEnableTruncation={setEnableTruncation}
                    />}
                </div>
            </div>
        </div>
    )
}

export default MultiQuestion
