import { MOBILE_RESPONSIVE_LIMIT } from '@app/app.constants'
import { css, useTheme } from '@emotion/react'
import Color from 'color'

export const CSS_QUESTION_TYPE = () => {
    const theme = useTheme()

    return css`
        & .question-scale {
            & .list-allowed {
                @media (max-width: ${ MOBILE_RESPONSIVE_LIMIT })  {
                    margin-block-end: 25px;
                }

                @media (min-width: ${ MOBILE_RESPONSIVE_LIMIT })  {
                    margin-block-end: 45px;
                }
            }

            & .card.scale-card, & .card.checkbox-card {
                box-shadow: 0px 2px 10px 0px ${
                    (() => {
                        const color = Color(theme.config.endUser?.reasonWithMe.boxShadowColor || '')
                        return 'rgba(' + [
                            color.rgb().array()[0],
                            color.rgb().array()[1],
                            color.rgb().array()[2],
                            '0.05'
                        ].join(', ') + ')'
                    })()
                };

                & > .card-body {
                    padding: 0;

                    & > div {
                        padding-inline: 50px;
                        padding-block: 10px;
                    }
                }
            }

            & .card.scale-card {
                margin-block-end: 20px;
            }

            & .btn-group-checkbox-list {
                margin-block-end: 15px;
            }
        }

        & .question-multi-list, & .question-list {
            margin-block-end: 80px;

            & .form-control-wrapper input[type=search] {
                background-color: ${ theme.config.endUser
                    ?.reasonWithMe.formControlInputBackgroundColor || '' };
            }
        }

        & .question-single-date, & .question-open, & .question-number-input,
        & .question-float-input, & .question-bool, & .question-text {
            margin-block-end: 80px;
        }

        & .question-multi-question {
            & .multi-question-card {
                box-shadow: 0px 2px 10px 0px ${
                    (() => {
                        const color = Color(theme.config.endUser?.reasonWithMe.boxShadowColor || '')
                        return 'rgba(' + [
                            color.rgb().array()[0],
                            color.rgb().array()[1],
                            color.rgb().array()[2],
                            '0.05'
                        ].join(', ') + ')'
                    })()
                };

                & > .card-body {
                    padding: 1.2em;
                }
            }
        }

        /* for all types just in case */

        & .question-plussign {
            background-color: ${ theme.config.endUser
                    ?.reasonWithMe.openList.plusButton.background || '' };
        }

        & .question-resume {
            .popup-button {
                width: 170px;
            }
        }
        
        & .submit-button {
            /** to assist with responsiveness */
            max-width: 350px;
            width: 100%;
        }

    `
}

const GlobalStyle = () => {
    return css`
        
        //also present in the careplan step page
        .reason-with-me-page {

            & .main-content {

                ${ CSS_QUESTION_TYPE() }
                
            }

            @media (min-width: ${ MOBILE_RESPONSIVE_LIMIT })  {
                
                & .main-content {

                    //padding-top is better than margin-top. if you do margin top.
                    //any components above will also go down.
                    padding-top: 240px;

                    & .form-container {
                        /* padding-block-start: 7vh; */
                    }
                }
            }

            @media (max-width: ${ MOBILE_RESPONSIVE_LIMIT })  {

                & .header {
                    height: 58px;

                    & img {
                        width: 98px;
                    }
                }

                
                & .main-content {

                    padding-top: 113px;
                    position: absolute;
                    //100% minus the height of the previous divs. 100% means height of viewport
                    //in this case
                    /*                 
                    /* height: calc(100% - 113px - 58px); */
                    max-height: calc(100% - 60px);
                    height: 100%;


                    & .form-container {
                        display: flex;
                        /* padding-block-start: 5vh; */
                        flex-direction: column;
                        height: 100%;

                        & > div:nth-of-type(1) {
                            flex: 1;
                        }

                        & > div:nth-of-type(2) {
                            flex: 0;
                        }

                    }

                }


            }  
        }

    `
}

export default GlobalStyle
