import { AppState } from '@app/types/type.app'
import { TokenData } from '@app/types/type.token'
import { MutationActionCreatorResult } from '@reduxjs/toolkit/dist/query/core/buildInitiate'
import { getYear } from 'date-fns'
import { range } from 'lodash'
import { ToastOptions } from 'react-toastify'

export const URL = {
    API: 'https://api.demo.ikherstel.nl/'
}

// sidebar width for all stylesheets.
export const SIDEBAR_WIDTH = 292

export const ACTION_MUTATION_PROMISE = null as unknown as MutationActionCreatorResult<any>
export const DEBOUNCE_SEARCH_TIME = 1000

export const PLACEHOLDER_IMAGE = '/images_new/onboarding/stap-02.png'

export const EMPTY_TOKEN_DATA: TokenData = {
    // an id for an empty token data will always be an empty string
    id: '',
    value: '',
    valid: false,
    details: {
        sub: '',
        pic: '',
        iat: 0,
        exp: 0,
        rid: [],
        aid: '',
        name: '',
        locale: '',
        fullName: '',
        age: 0,
        roid: '',
        ss: {
            admin: '',
            endUser: ''
        }
    },
    mode: ''
}

export const TOASTIFY_DEFAULT_OPTIONS: ToastOptions = {
    position: 'top-right',
    rtl: false,
    draggable: true,
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: false,
    pauseOnHover: false,
    pauseOnFocusLoss: false
}

// date formats for dutch and date formats for english
export const DATE_FORMAT: {
    'en-us': AppState['dateFormats'],
    'nl-nl': AppState['dateFormats'],
} = {
    'en-us': {
        format1: 'dd-MM-yyyy',
        format2: 'EEE. MMM dd yyyy',
        format3: 'MMMM d, yyyy',
        format4: 'dd-MM-yyyy hh:mm:ss aa',
        format5: 'HH:mm',
        format6: 'EEEE, d MMMM yyyy',
        format7: 'do MMM yyyy',
        format8: 'dd MMM yyyy',
        format9: 'dd/MM/yyyy HH:mm'

    },
    'nl-nl': {
        format1: 'dd-MM-yyyy',
        format2: 'EEEEEE. MMM dd yyyy',
        format3: 'MMMM d, yyyy',
        format4: 'dd-MM-yyyy hh:mm:ss aa',
        format5: 'HH:mm',
        format6: 'EEEE, d MMMM yyyy',
        format7: 'do MMM yyyy',
        format8: 'dd MMM yyyy',
        format9: 'dd/MM/yyyy HH:mm'

    }
}

/** default date format */
// export const DATE_FORMAT = 'dd-MM-yyyy'
// export const DATE_FORMAT_2 = 'EEE. MMM dd yyyy'
// export const DATE_FORMAT_3 = 'EEE. MMM dd yyyy'
// export const DATE_FORMAT_4 = 'MMMM d, yyyy'
// export const FORMAT_DATE_TIME = 'dd-MM-yyyy hh:mm:ss aa'
// export const FORMAT_TIME = 'HH:mm'
// export const DATE_FORMAT_6 = 'EEEE, d MMMM yyyy'
// export const DATE_FORMAT_7 = 'do MMM yyyy'
// export const DATE_FORMAT_8 = 'dd MMM yyyy'

export const YEARS = range(1990, getYear(new Date()) + 1, 1)

export const TIMER = {
    IDLE: 8 * 60 * 1000,
    EXPIRED: 10 * 60 * 1000
    // IDLE: 4 * 60 * 1000,
    // EXPIRED: 5 * 60 * 1000
    // IDLE: 5 * 1000,
    // EXPIRED: 10 * 1000
}

// export const MOBILE_RESPONSIVE_LIMIT = '480px'
export const MOBILE_RESPONSIVE_LIMIT = '700px'
export const DESKTOP_RESPONSIVE_LIMIT = '1378px'

export const FIREBASE_CONFIG = {
    apiKey: 'AIzaSyCHrhDR6ekRkRWDJj9zP-RmO8iBbal5JIg',
    authDomain: 'ikherstel-64816.firebaseapp.com',
    projectId: 'ikherstel-64816',
    storageBucket: 'ikherstel-64816.appspot.com',
    messagingSenderId: '41228020744',
    appId: '1:41228020744:web:36767c47bc7a937f09b5bd'
}
export const PAGE_COUNT = [
    {
        label: '10',
        value: 10
    },
    {
        label: '25',
        value: 25
    },
    {
        label: '50',
        value: 50
    },
    {
        label: '100',
        value: 100
    }
]

export const UNLINKED_LIMIT = 10
